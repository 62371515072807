
import { Component, Mixins } from "vue-property-decorator";
import { namespace } from "vuex-class";
import tableCheck from "@/mixins/table-check";
import TableEnableFlag from "@/components/scope/table-enable-flag.vue";
import { emptyToLine } from "@/utils/common";
import dayjs from "dayjs";
import { Pageniation, Loading } from "@/utils/decorator";
import t from "@common/src/i18n/t";
const announcement = namespace("announcement");
const base = namespace("base");
const consult = namespace("consult");
const learning = namespace("learning");

@Component({
  components: { TableEnableFlag }
})
@Pageniation("loadListData")
export default class TenantList extends Mixins(tableCheck) {
  @base.Action getDictionaryList;
  @announcement.Action getAnnouncementList;
  @announcement.Action endAnnouncement;
  @consult.Action queryUserAccountList;
  @learning.Action queryExamPaperList;
  @learning.Action queryExamSignUserList;
  @learning.Action revokeExam;
  dayjs = dayjs;
  visible: boolean = true;
  showSignPeople: boolean = false;
  searchForm = {
    examPaperTempName: "",
    examBeginTimeStart: "",
    examBeginTimeStop: "",
    examPaperTempStatus: ""
  };
  emptyToLine = emptyToLine;
  examPaperList: any[] = [];
  storeTypeList: any[] = [];
  tenantList: any[] = [];
  publisherList: any = [];
  examStatusList: any = [];
  get searchList() {
    return [
      {
        label: t("v210831.test-name-not"),
        type: "input",
        value: "",
        prop: "examPaperTempName"
      },
      {
        label: t("v210901.opening-time-not"),
        type: "daterange",
        value: "",
        prop: "examBeginTimeStart"
      },
      {
        label: t("v210831.examination-status"),
        type: "select",
        value: "",
        selectList: this.examStatusList,
        prop: "examPaperTempStatus"
      }
    ];
  }
  //多行文本省略
  filters(value) {
    if (!value) return "";
    if (value.length > 20) {
      return value.slice(0, 20) + "...";
    }
    return value;
  }
  handleRevoke(row) {
    this.revokeExam({
      examPaperTempCode: row.examPaperTempCode
    }).then(() => {
      this.$message.success(t("v210831.the-exam-has"));
      this.loadListData();
    });
  }
  actionRow: any = {};
  signUserList: any = [];
  handleShowSign(row) {
    this.actionRow = row;
    this.showSignPeople = true;
    this.queryExamSignUserList({
      learnContentCode: row.learnContentCode
    });
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        if (item.prop === "examBeginTimeStart") {
          this.searchForm.examBeginTimeStart = item.value ? item.value[0] : "";
          this.searchForm.examBeginTimeStop = item.value ? item.value[1] : "";
        } else {
          this.searchForm[item.prop] = item.value;
        }
      }
    });
    this.loadListData();
  }
  created() {
    this.init();
  }
  @Loading()
  loadListData() {
    return this.queryExamPaperList({
      ...this.searchForm,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      this.examPaperList = data.data.list;
      return data;
    });
  }
  async init() {
    this.loadListData();
    this.getDictionaryList("store_type").then(data => {
      this.storeTypeList = data;
    });
    this.getDictionaryList("exam_paper_temp_status").then(data => {
      this.examStatusList = data;
    });

    //获取发布人列表
    this.getUserAccount();
  }
  getUserAccount() {
    this.queryUserAccountList({
      pageNum: 1,
      pageSize: 999
    }).then(data => {
      this.publisherList = data.data.list
        ? data.data.list.map(item => {
            item.label = item.firstName;
            item.value = item.firstName;
            return item;
          })
        : [];
    });
  }
  get statusList() {
    return [
      {
        label: t("v210831.not-started"),
        value: 0
      },
      {
        label: t("v210831.in-the-announcement"),
        value: 1
      },
      {
        label: t("v210831.has-ended"),
        value: 2
      }
    ];
  }
  handleEdit(row) {
    this.$router.push(`/learning/exam-edit/${row.examPaperTempCode}`);
  }
  handleCommand(command) {
    console.log("[ command ]", command);
    if (command === "edit") {
      this.handleEdit(this.hoverRow);
    } else if (command === "copy") {
      console.log("[ copy ]", this.hoverRow);
      this.$router.push(`/learning/exam-add?id=${this.hoverRow.examPaperTempCode}`);
    }
  }
  hoverRow = undefined;
  handleSetCurrentRow(visible, row) {
    this.hoverRow = row;
  }
  // ///
}
